import { Box } from "@mui/material";

const FlexBetween = (props) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...props}
    />
  );
};
export default FlexBetween;
