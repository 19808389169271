import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  reducerPath: "adminApi",
  tagTypes: [
    "Comps",
    "Couples",
    "Dancers",
    "Event",
    "Events",
    "Participant",
    "Participants",
    "ResultEvents",
    "ResultRounds",
    "ResultDances",
    "ResultCompetitors",
  ],
  endpoints: (build) => ({
    getComps: build.query({
      query: (comp_year_id) =>
        comp_year_id ? `comps/${comp_year_id}` : "comps",
      providesTags: ["Comps"],
    }),
    getCouples: build.query({
      query: (comp_year_id) => `comps/${comp_year_id}/couples`,
      providesTags: ["Couples"],
    }),
    getDancers: build.query({
      query: (comp_year_id) => `comps/${comp_year_id}/attendees`,
      providesTags: ["Dancers"],
    }),
    getEvent: build.query({
      query: ({ comp_year_id, event_id }) =>
        `comps/${comp_year_id}/events/${event_id}`,
      providesTags: ["Event"],
    }),
    getEvents: build.query({
      query: ({ comp_year_id, event_ids }) => {
        return event_ids.length > 0
          ? `comps/${comp_year_id}/events?ids=${event_ids.join(",")}`
          : `comps/${comp_year_id}/events?ids=-1`;
      },
      providesTags: ["Events"],
    }),
    getResultParticipant: build.query({
      query: ({ comp_year_id, participant_id }) =>
        `comps/${comp_year_id}/results/participants/${participant_id}`,
      providesTags: ["Participant"],
    }),
    getResultParticipants: build.query({
      query: (comp_year_id) => `comps/${comp_year_id}/results/participants`,
      providesTags: ["Participants"],
    }),
    getResultJudges: build.query({
      query: ({ comp_year_id}) =>
        `comps/${comp_year_id}/results/judges`,
      providesTags: ["ResultJudges"],
      transformResponse: (response) => {
        return Object.fromEntries(response[0].judges.map((x) => [x.judge_id, x.name.join(" ")]));
      },

    }),
    getResultEvents: build.query({
      query: ({ comp_year_id, event_ids }) => ({
        url: `comps/${comp_year_id}/results/events`,
        method: "GET",
        params: {
          ids: event_ids.length > 0 ? event_ids.join(",") : -1,
        },
      }),
      providesTags: ["ResultEvents"],
      transformResponse: (response) => {
        return Object.fromEntries(response.map((x) => [x.event_id, x]));
      },
    }),
    getResultRounds: build.query({
      query: ({ comp_year_id, round_ids }) => ({
        url: `comps/${comp_year_id}/results/rounds`,
        method: "GET",
        params: {
          ids: round_ids.length > 0 ? round_ids.join(",") : -1,
        },
      }),
      providesTags: ["ResultRounds"],
      transformResponse: (response) => {
        return Object.fromEntries(response.map((x) => [x.round_id, x]));
      },
    }),
    getResultDances: build.query({
      query: ({ comp_year_id, round_ids, dance_ids }) => ({
        url: `comps/${comp_year_id}/results/dances`,
        method: "GET",
        params: {
          ids: dance_ids.length > 0 ? dance_ids.join(",") : -1,
          round_ids: round_ids.length > 0 ? round_ids.join(",") : -1,
        },
      }),
      providesTags: ["ResultDances"],
      transformResponse: (response) => {
        return Object.fromEntries(
          response.map((x) => [[x.round_id, x.dance_id].join(","), x])
        );
      },
    }),
    getResultCompetitors: build.query({
      query: ({ comp_year_id, competitor_ids }) => ({
        url: `comps/${comp_year_id}/results/competitors`,
        method: "GET",
        params: {
          ids: competitor_ids.length > 0 ? competitor_ids.join(",") : -1,
        },
      }),
      providesTags: ["ResultCompetitors"],
      transformResponse: (response) => {
        return Object.fromEntries(
          response.map((x) => [x.competitor_id, x])
        );
      },
    }),
  }),
});

export const {
  useGetDancersQuery,
  useGetCompsQuery,
  useGetCouplesQuery,
  useGetEventQuery,
  useGetEventsQuery,
  useGetResultCompetitorsQuery,
  useGetResultParticipantQuery,
  useGetResultParticipantsQuery,
  useGetResultJudgesQuery,
  useGetResultEventsQuery,
  useGetResultRoundsQuery,
  useGetResultDancesQuery,
} = api;
