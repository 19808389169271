import React, { useEffect, useMemo } from "react";
import {
  Link as RouterLink,
  useParams,
  useOutletContext,
} from "react-router-dom";
import { Box, Link, Chip, Stack, Typography, useTheme } from "@mui/material";
import {
  useGetCompsQuery,
  useGetCouplesQuery,
  useGetDancersQuery,
  useGetEventsQuery,
} from "state/api";
import Header from "components/Header";
import ContentDataGrid from "components/ContentDataGrid";

const HeatListEvent = ({ isMobile, data }) => {
  const theme = useTheme();

  return (
    <Box mt={3}>
      <Stack direction="row" spacing={1} alignItems="center">
        {isMobile && <Chip label={data?.heat} size="small" />}
        <Typography
          variant="h5"
          color={theme.palette.primary[600]}
          fontWeight="bold"
          style={{ whiteSpace: "pre-line" }}
        >
          {data?.event_name.replace("Events", "Events\n")}
        </Typography>
      </Stack>
      {data.rounds.map((round) => (
        <Round
          name={round.round_name}
          time={round.round_time}
          key={round?._id}
        />
      ))}
      {data.couples.map((couple) => (
        <Couple data={couple} comp_year_id={data.comp_year_id} />
      ))}
      <br />
    </Box>
  );
};

const Couple = ({ data, comp_year_id }) => {
  const theme = useTheme();
  return (
    <Box>
      -{" "}
      {data.map((dancer, index) => (
        <span key={dancer.id}>
          <Link
            component={RouterLink}
            to={`/comps/${comp_year_id}/heatlist/${dancer.id}`}
            underline="none"
            color={theme.palette.primary[200]}
          >
            {dancer.name}
          </Link>
          {index < data.length - 1 && " and "}
        </span>
      ))}
    </Box>
  );
};

const date_options = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: true,
  weekday: "long",
  timeZone: "UTC",
};

const Round = ({ name, time }) => {
  const theme = useTheme();
  const date = new Date(time).toLocaleString("en-US", date_options);

  return (
    <Typography variant="h5" color={theme.palette.primary[400]}>
      {name} - {date}
    </Typography>
  );
};

const HeatListEvents = () => {
  const { comp_year_id, attendee_id } = useParams();
  const { data: compData } = useGetCompsQuery(comp_year_id);
  const { data: dancersData } = useGetDancersQuery(comp_year_id);
  const { data: couplesData } = useGetCouplesQuery(comp_year_id);
  const { isNonMobile } = useOutletContext();

  const comp = compData ? compData[0] : {};

  const dancer = useMemo(
    () => dancersData?.find((x) => x.ID === parseInt(attendee_id)),
    [dancersData, attendee_id]
  );

  const dancers = {};
  if (dancersData) {
    for (let dancer of dancersData) {
      dancers[dancer.ID] = dancer.Name.join(" ");
    }
  }

  const couples = {};
  if (couplesData) {
    for (let couple of couplesData) {
      couples[couple.couple_id] = couple.dancer_ids.map((x) => ({
        id: x,
        name: dancers[x],
      }));
    }
  }

  const couple_events =
    couplesData && dancer
      ? couplesData.filter((x) => x.dancer_ids.includes(dancer.ID))
      : [];

  const event_ids = couple_events?.map((obj) => obj.event_ids).flat();
  const { data } = useGetEventsQuery({ comp_year_id, event_ids });

  const row_data = data?.map((x) => ({
    comp_year_id,
    event_name: x.event_name,
    heat: x.heat,
    rounds: x.rounds,
    couples: x.couple_ids.map((id) => couples[id]),
  }));

  useEffect(() => {
    document.title = `Heat List - ${dancer?.Name.join(" ")}`;
  }, [dancer]);

  const columns = [
    {
      field: "heat",
      headerName: "Heat",
    },
    {
      field: "event_name",
      headerName: "Event",
      flex: 1,
      renderCell: (params) => (
        <HeatListEvent
          isMobile={!isNonMobile}
          data={params.row}
          key={params.row.heat}
        />
      ),
    },
    { field: "couples" },
  ];

  return (
    <Box m="1rem 1rem">
      <Header
        title={dancer?.Name?.join(" ")}
        subtitle={comp?.comp_year_name}
        subtitleLinkTo={`/comps/${comp?.comp_year_id}/heatlist`}
      />
      <ContentDataGrid
        // loading={isLoading || !row_data}
        loading={!row_data}
        getRowId={(row) => row.heat}
        rows={row_data || []}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "heat", sort: "asc" }],
          },
          columns: {
            columnVisibilityModel: {
              heat: isNonMobile,
              couples: false,
            },
          },
        }}
        autoPageSize={false}
        autoHeight={true}
        hideFooter={true}
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default HeatListEvents;
