import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

const Logo = (props) => {
  return (
    <Link to={"/"} style={{ textDecoration: "none" }}>
      <Typography
        variant="h4"
        fontWeight="bold"
        fontFamily="Pacifico"
        style={{
          background:
            "linear-gradient(90deg, #22A1E3 5%, #d83c88 50%, #FC9D14 95%)",
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
        }}
        {...props}
      >
        DANCEPAPA
      </Typography>
    </Link>
  );
};

export default Logo;
