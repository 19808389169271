import React from "react";
import { useOutletContext } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";

const QuickSearchToolbar = () => {
  return (
    <Box
      sx={{
        padding: 1,
        display: "flex",
      }}
    >
      <GridToolbarQuickFilter
        style={{ flex: 1 }}
        quickFilterParser={(searchInput: string) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
        debounceMs={600}
      />
    </Box>
  );
};

const ContentDataGrid = (props) => {
  const theme = useTheme();
  const { isSidebarOpen, isNonMobile } = useOutletContext();
  const sideBarWidthDefault = isNonMobile ? "250px" : "0px";
  const sideBarWidth = isSidebarOpen ? sideBarWidthDefault : "0px";
  const margin_h = "1rem";

  return (
    <Box
      mt="40px"
      width={`calc(100vw - ${sideBarWidth} - 2 * ${margin_h})`}
      height="calc(100vh - 200px)"
    >
      <DataGrid
        autoPageSize={true}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: QuickSearchToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              style: { flex: 1 },
              debounceMs: 500,
            },
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
          },
        }}
        sx={{
          boxShadow: 2,
          backgroundColor: theme.palette.neutral[0],
          border: "none",
          fontSize: "1em",
          padding: isNonMobile ? "1rem" : "0.5rem" ,
          "& .MuiDataGrid-row": {
            // padding: "0 1.5em",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: theme.palette.neutral[50],
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            borderBottomWidth: 0,
          },
          "& .MuiDataGrid-columnHeaders": {
            // padding: "0 1.5em",
            borderBottomColor: theme.palette.neutral[50],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 1000,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
          "& .MuiDataGrid-overlay": {
            background: "transparent",
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
            {
              outline: "none",
            },
        }}
        {...props}
      />
    </Box>
  );
};

export default ContentDataGrid;
