import { Typography, Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

const Header = ({ title, subtitle, subtitleLinkTo }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        variant="h4"
        color={theme.palette.neutral[0]}
        fontWeight="bold"
        sx={{ mb: "5px" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={theme.palette.primary[400]}>
        {subtitleLinkTo ? (
          <Link
            to={subtitleLinkTo}
            style={{
              color: theme.palette.primary[200],
              textDecoration: "none",
            }}
          >
            {subtitle}
          </Link>
        ) : (
          subtitle
        )}
      </Typography>
    </Box>
  );
};

export default Header;
// <Link
//   to={`/comps/${comp?.comp_year_id}/heatlist`}
//   style={{ color: "white", textDecoration: "none" }}
// >
//   {comp?.comp_year_name}
// </Link>
