// import React, { useState } from "react";
import {
  // LightModeOutlined,
  // DarkModeOutlined,
  Menu as MenuIcon,
  // Search,
  // SettingsOutlined,
  // ArrowDropDownOutlined,
} from "@mui/icons-material";
// import FlexBetween from "components/FlexBetween";
import Logo from "components/Logo";
// import { useDispatch } from "react-redux";
// import { setMode } from "state";
// import profileImage from "assets/profile.jpeg";
import {
  AppBar,
  // Button,
  Box,
  // Typography,
  IconButton,
  // InputBase,
  Toolbar,
  // Menu,
  // MenuItem,
  // useTheme,
} from "@mui/material";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
  // const dispatch = useDispatch();
  // const theme = useTheme();
  // const [anchorEl, setAnchorEl] = useState(null);
  // const isOpen = Boolean(anchorEl);
  // const handleClick = (event) => setAnchorEl(event.currentTarget);
  // const handleClose = () => setAnchorEl(null);

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <Box width="10%">
          <IconButton
            color="neutral"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {/* RIGHT SIDE */}
        {(!isNonMobile || !isSidebarOpen) && (
          <Box
            display="flex"
            justifyContent="center"
            gap="0.5rem"
            paddingRight="10%"
            paddingTop="1rem"
            width="90%"
            minHeight="56px"
          >
            <Logo variant="h4" />
          </Box>
        )}
        {
          // <IconButton color="neutral" onClick={() => dispatch(setMode())}>
          //       {theme.palette.mode === "dark" ? (
          //         <DarkModeOutlined sx={{ fontSize: "25px" }} />
          //       ) : (
          //         <LightModeOutlined sx={{ fontSize: "25px" }} />
          //       )}
          // </IconButton>
        }
        {
          // <IconButton>
          //   <SettingsOutlined sx={{ fontSize: "25px" }} />
          // </IconButton>
          //
          // <FlexBetween>
          //   <Button
          //     onClick={handleClick}
          //     sx={{
          //       display: "flex",
          //       justifyContent: "space-between",
          //       alignItems: "center",
          //       textTransform: "none",
          //       gap: "1rem",
          //     }}
          //   >
          //     <Box
          //       component="img"
          //       alt="profile"
          //       src={profileImage}
          //       height="32px"
          //       width="32px"
          //       borderRadius="50%"
          //       sx={{ objectFit: "cover" }}
          //     />
          //     <Box textAlign="left">
          //       <Typography
          //         fontWeight="bold"
          //         fontSize="0.85rem"
          //         sx={{ color: theme.palette.secondary[100] }}
          //       >
          //         {user.name}
          //       </Typography>
          //       <Typography
          //         fontSize="0.75rem"
          //         sx={{ color: theme.palette.secondary[200] }}
          //       >
          //         {user.occupation}
          //       </Typography>
          //     </Box>
          //     <ArrowDropDownOutlined
          //       sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
          //     />
          //   </Button>
          //   <Menu
          //     anchorEl={anchorEl}
          //     open={isOpen}
          //     onClose={handleClose}
          //     anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          //   >
          //     <MenuItem onClick={handleClose}>Log Out</MenuItem>
          //   </Menu>
          // </FlexBetween>
        }
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
