import React, { useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useGetCompsQuery, useGetDancersQuery } from "state/api";
import Header from "components/Header";
import ContentDataGrid from "components/ContentDataGrid";

const HeatList = () => {
  const { comp_year_id } = useParams();
  const navigate = useNavigate();
  const { data: compData } = useGetCompsQuery(comp_year_id);
  const { data: dancersData, isLoading: isLoadingDancers } =
    useGetDancersQuery(comp_year_id);

  const data = dancersData
    ? dancersData
        .filter((item) => item?.Type === "Attendee")
        .map((item) => ({
          id: item.ID,
          first_name: item.Name[0],
          last_name: item.Name[1],
        }))
    : [];

  const comp = useMemo(() => (compData ? compData[0] : {}), [compData]);

  useEffect(() => {
    document.title = `DancePapa - ${comp?.comp_year_name}`;
  }, [comp]);

  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 0.5,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 0.5,
    },
  ];

  return (
    <Box m="1rem 1rem">
      <Header title={comp?.comp_year_name} subtitle="Heat list" />
      <ContentDataGrid
        loading={isLoadingDancers || !data}
        getRowId={(row) => row.id}
        rows={data || []}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "last_name", sort: "asc" }],
          },
        }}
        onRowClick={(params) => {
          navigate(`/comps/${comp_year_id}/heatlist/${params.id}`);
        }}
      />
    </Box>
  );
};

export default HeatList;
