import React from "react";
import {
  Box,
  // Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  // SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  // HomeOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import Logo from "./Logo";
// import profileImage from "assets/profile.jpeg";

const navItems = [
  // {
  //   text: "Dashboard",
  //   icon: <HomeOutlined />,
  // },
  {
    text: "Comps",
    icon: <WorkspacePremiumOutlined />,
  },
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setActive(pathname.substring(1).split("/").shift());
  }, [pathname]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: isNonMobile ? drawerWidth : "100%",
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              background: theme.palette.primary[900],
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "1px",
              borderColor: theme.palette.primary[200],
              width: isNonMobile ? drawerWidth : "100%",
            },
          }}
        >
          <Box width="100%">
            <Box m="0.5rem 1rem 2rem 1rem">
              <FlexBetween alignItems="start">
                <Box
                  display="flex"
                  justifyContent="center"
                  width="90%"
                  paddingLeft="10%"
                  paddingTop="0.5rem"
                >
                  <Logo variant={isNonMobile ? "h3" : "h4"} />
                </Box>
                {!isNonMobile && (
                  <Box width="10%">
                    <IconButton
                      color="neutral"
                      onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    >
                      <ChevronLeft />
                    </IconButton>
                  </Box>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography
                      key={text}
                      sx={{
                        m: "2.25rem 0 1rem 3rem",
                        color: theme.palette.primary[300],
                      }}
                    >
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase();

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor: "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[100]
                            : theme.palette.neutral[0],
                        fontWeight: 1000,
                        borderTopRightRadius: "0.5em",
                        borderBottomRightRadius: "0.5em",
                        "&::before": {
                          content: '""',
                          background:
                            active === lcText
                              ? theme.palette.primary[100]
                              : "transparent",
                          padding: 0,
                          margin: 0,
                          display: "block",
                          width: "10%",
                          height: "1px",
                          position: "absolute",
                          left: 0,
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[100]
                              : theme.palette.neutral[0],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText disableTypography primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
          {
            // <Box position="absolute" bottom="2rem">
            //   <Divider />
            //   <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">
            //     <Box
            //       component="img"
            //       alt="profile"
            //       src={profileImage}
            //       height="40px"
            //       width="40px"
            //       borderRadius="50%"
            //       sx={{ objectFit: "cover" }}
            //     />
            //     <Box textAlign="left">
            //       <Typography
            //         fontWeight="bold"
            //         fontSize="0.9rem"
            //         sx={{ color: theme.palette.secondary[100] }}
            //       >
            //         {user.name}
            //       </Typography>
            //       <Typography
            //         fontSize="0.8rem"
            //         sx={{ color: theme.palette.secondary[200] }}
            //       >
            //         {user.occupation}
            //       </Typography>
            //     </Box>
            //     <SettingsOutlined
            //       sx={{
            //         color: theme.palette.secondary[300],
            //         fontSize: "25px ",
            //       }}
            //     />
            //   </FlexBetween>
            // </Box>
          }
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
