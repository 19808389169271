import React, { useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import { useGetCompsQuery } from "state/api";
import Header from "components/Header";
import ContentDataGrid from "components/ContentDataGrid";

import RecentActorsIcon from "@mui/icons-material/RecentActors";
import GradingIcon from "@mui/icons-material/Grading";

const filter_for_relevant_comps = (data) => {
  if (!data) {
    return [];
  }

  // get the current date
  const today = new Date();

  // calculate the date range
  const fourWeeksAgo = new Date(today.getTime() - 28 * 24 * 60 * 60 * 1000);
  const threeMonthsLater = new Date(
    today.getTime() + 3 * 30 * 24 * 60 * 60 * 1000
  );

  // filter for events within the date range
  const filteredEvents = data.filter((event) => {
    const startDate = new Date(event.start_date);
    return startDate >= fourWeeksAgo && startDate <= threeMonthsLater;
  });

  return filteredEvents;
};

const renderCompLinks =
  (isNonMobile, navigate) =>
  ({ row }) => {
    return (
      <Box display="flex" flexDirection="row">
        {row?.generated_heatlist && (
          <Tooltip title="Heat List" placement="top" arrow>
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/comps/${row?.comp_year_id}/heatlist`);
              }}
            >
              <RecentActorsIcon sx={isNonMobile ? {} : { fontSize: 25 }} />
            </IconButton>
          </Tooltip>
        )}
        {row?.generated_result && (
          <Tooltip title="Result" placement="top" arrow>
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/comps/${row?.comp_year_id}/results`);
              }}
            >
              <GradingIcon sx={isNonMobile ? {} : { fontSize: 25 }} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };

const Comps = () => {
  const { data, isLoading } = useGetCompsQuery();
  const { isNonMobile } = useOutletContext();
  const navigate = useNavigate();
  const uniqueData = filter_for_relevant_comps(data);

  useEffect(() => {
    document.title = "DancePapa - Comps";
  }, []);

  const columnsNonMobile = [
    isNonMobile && {
      field: "start_date",
      headerName: "Date",
      // flex: 0.3,
      renderCell: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding zero-padding
          const day = String(date.getDate()).padStart(2, "0"); // Adding zero-padding

          return `${year}-${month}-${day}`;
        }

        return null;
      },
    },
    {
      field: "generated_heatlist",
      headerName: "",
      sortable: false,
      // flex: 0.3,
      renderCell: renderCompLinks(isNonMobile, navigate),
    },
    {
      field: "comp_year_name",
      headerName: "Name",
      flex: 1,
    },
  ];

  const columnsMobile = [
    {
      field: "generated_heatlist",
      headerName: "",
      sortable: false,
      // flex: 0.2,
      width: 100,
      renderCell: renderCompLinks(isNonMobile, navigate),
    },
    {
      field: "start_date",
      headerName: "Name - sorted by Date",
      flex: 1,
      renderCell: ({row}) => {
        const date = new Date(row?.start_date);
        const options = { day: "numeric", month: "long" };
        const dateString = date.toLocaleString("en-US", options);

        return (
          <Box display="block" py={1}>
            <Typography color="primary">{dateString}</Typography>
            <Box style={{ "text-overflow": "ellipsis" }}>
              {row?.comp_year_name}
            </Box>
          </Box>
        );
      },
    },
  ];

  const columns = isNonMobile ? columnsNonMobile : columnsMobile;
  const extra_props = isNonMobile ? {} : { getRowHeight: () => "auto" };

  return (
    <Box m="1rem 1rem">
      <Header title="Competitions" />
      <ContentDataGrid
        loading={isLoading || !uniqueData}
        getRowId={(row) => row.comp_year_id}
        rows={uniqueData || []}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "start_date", sort: "asc" }],
          },
        }}
        // onRowClick={(params) => {
        //   if (params?.row?.generated_heatlist) {
        //     navigate(`/comps/${params?.row?.comp_year_id}/heatlist`);
        //   }
        // }}
        {...extra_props}
      />
    </Box>
  );
};

export default Comps;
