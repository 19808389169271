import React, { useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useGetCompsQuery, useGetResultParticipantsQuery } from "state/api";
import Header from "components/Header";
import ContentDataGrid from "components/ContentDataGrid";

const Results = () => {
  const { comp_year_id } = useParams();
  const navigate = useNavigate();
  const { data: compData } = useGetCompsQuery(comp_year_id);
  const { data, isLoading } = useGetResultParticipantsQuery(comp_year_id);

  const comp = useMemo(() => (compData ? compData[0] : {}), [compData]);

  useEffect(() => {
    document.title = `DancePapa - ${comp?.comp_year_name}`;
  }, [comp]);

  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 0.5,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 0.5,
    },
  ];

  return (
    <Box m="1rem 1rem">
      <Header title={comp?.comp_year_name} subtitle="Results" />
      <ContentDataGrid
        loading={isLoading || !data}
        getRowId={(row) => row.id}
        rows={data || []}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "last_name", sort: "asc" }],
          },
        }}
        onRowClick={(params) => {
          navigate(`/comps/${comp_year_id}/results/${params.id}`);
        }}
      />
    </Box>
  );
};

export default Results;
