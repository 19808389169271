import React, { useEffect, useMemo } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import { Box, Typography, Tooltip, useTheme } from "@mui/material";
import {
  useGetCompsQuery,
  useGetResultJudgesQuery,
  useGetResultParticipantQuery,
  useGetResultEventsQuery,
  useGetResultRoundsQuery,
  useGetResultDancesQuery,
  useGetResultCompetitorsQuery,
} from "state/api";
import Header from "components/Header";
// import InlineDataGrid from "components/InlineDataGrid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EventResult = ({ eventData, context }) => {
  const theme = useTheme();
  const { roundsData } = context;

  return (
    <Box width="100%">
      {eventData &&
        eventData.rounds.map((round_id) => {
          const roundData = roundsData?.[round_id] ?? {};
          const control_id = `event-${eventData.event_id}-round-${roundData.round_id}`;
          return (
            <Accordion
              defaultExpanded={eventData.rounds.length === 1}
              variant="outlined"
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${control_id}-content`}
                id={`panel-${control_id}-header`}
              >
                <Typography
                  variant="h5"
                  color={theme.palette.primary[400]}
                  fontWeight="bold"
                >
                  {roundData?.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingX: "0" }}>
                <Round roundData={roundData} context={context} />
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
};

const Round = ({ roundData, context }) => {
  const { dancesData } = context;

  return (
    <Box>
      {roundData?.dances?.map((dance_id) => (
        <Dance
          danceData={dancesData?.[`${roundData.round_id},${dance_id}`] ?? {}}
          roundData={roundData}
          context={context}
        />
      ))}
      {roundData?.name === "Final" && (
        <RoundSummary roundData={roundData} context={context} />
      )}
    </Box>
  );
};

const RoundSummary = ({ roundData, context }) => {
  const theme = useTheme();
  const { dancesData, competitorsData } = context;
  const dances = roundData?.dances?.map((dance_id) => {
    return dancesData?.[`${roundData.round_id},${dance_id}`]
      ?.dance_abbreviation;
  });

  const rows = roundData?.summary?.competitors?.map((competitor) => {
    const zipped = dances?.map((value, index) => [
      value,
      competitor.marks[index],
    ]);
    const marks = Object.fromEntries(zipped);
    return {
      bib: competitor.bib,
      dancers: competitorsData?.[competitor.id]?.participants
        ?.map((participant) => participant.name.join(" "))
        ?.join(" & "),
      result: competitor?.result.at(competitor?.result?.length - 1),
      ...marks,
    };
  });

  const dance_columns = roundData?.dances
    ? roundData?.dances.map((dance_id) => {
        const dance = dancesData?.[`${roundData.round_id},${dance_id}`];
        return {
          field: dance?.dance_abbreviation,
          headerName: dance?.dance_abbreviation,
          renderCell: (row) => {
            return (
              <Tooltip
                title={dance?.dance_name}
                placement="top"
                enterTouchDelay={0}
                arrow
              >
                <Typography>{row?.[dance?.dance_abbreviation]}</Typography>
              </Tooltip>
            );
          },
        };
      })
    : [];

  const columns = [
    {
      field: "dancers",
      headerName: "RESULT",
      renderCell: (row) => {
        return (
          <Box
            component="div"
            sx={{ display: "inline-flex", whiteSpace: "nowrap" }}
          >
            <Typography sx={{ fontWeight: "bold" }}>{row.bib}</Typography>
            {"\u2001"}
            <Typography>{row.dancers}</Typography>
          </Box>
        );
      },
    },
    ...dance_columns,
    {
      field: "result",
      headerName: "Result",
      renderCell: (row) => {
        return (
          <Typography
            sx={{ fontWeight: "bold" }}
            color={theme.palette.primary[200]}
          >
            {row.result}
          </Typography>
        );
      },
    },
  ];

  return (
    <Box>
      <TableContainer component={Box}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => {
                return (
                  <TableCell
                    style={index !== columns.length - 1 ? { width: "1px" } : {}}
                  >
                    <Typography fontWeight="bold">
                      {column?.headerName}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow
                key={row.bib}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map((column) => {
                  const content = column?.renderCell
                    ? column.renderCell(row)
                    : row?.[column?.field];

                  return <TableCell>{content}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const normalizeMarks = (mark) => {
  return mark === 1 ? "X" : "";
};

const Dance = ({ danceData, roundData, context }) => {
  const theme = useTheme();
  const { competitorsData, judgesData } = context;
  // const theme = useTheme();
  // console.log("round", roundData);
  // console.log("dance", danceData);

  const rows = danceData?.competitors?.map((competitor) => {
    const zipped = danceData?.judges?.map((value, index) => [
      value,
      roundData.scoring_method === "Skated"
        ? competitor.marks[index]
        : normalizeMarks(competitor.marks[index]),
    ]);
    const marks = Object.fromEntries(zipped);
    return {
      bib: competitor.bib,
      dancers: competitorsData?.[competitor.id]?.participants
        ?.map((participant) => participant.name.join(" "))
        ?.join(" & "),
      result: competitor?.result,
      total: competitor.marks.reduce((partialSum, a) => partialSum + a, 0),
      ...marks,
    };
  });
  // console.log(rows);

  const judges = danceData?.judges;
  const judge_columns = judges
    ? judges.map((judge) => ({
        field: judge,
        width: 5,
        headerName: judge,
        description: judgesData?.[judge],
        sortable: false,
        renderCell: (row) => {
          return (
            <Tooltip
              title={judgesData?.[judge]}
              placement="top"
              enterTouchDelay={0}
              arrow
            >
              <Typography>{row?.[judge]}</Typography>
            </Tooltip>
          );
        },
      }))
    : [];

  const columns = [
    {
      field: "dancers",
      headerName: danceData.dance_name,
      renderCell: (row) => {
        return (
          <Box
            component="div"
            sx={{ display: "inline-flex", whiteSpace: "nowrap" }}
          >
            <Typography sx={{ fontWeight: "bold" }}>{row.bib}</Typography>
            {"\u2001"}
            <Typography>{row.dancers}</Typography>
          </Box>
        );
      },
    },
    ...judge_columns,
  ];

  if (roundData?.scoring_method === "Skated") {
    columns.push({
      field: "result",
      headerName: "Result",
      renderCell: (row) => {
        return (
          <Typography
            sx={{ fontWeight: "bold" }}
            color={theme.palette.primary[200]}
          >
            {row.result}
          </Typography>
        );
      },
    });
  } else {
    columns.push({
      field: "total",
      headerName: "Total",
      renderCell: (row) => {
        return (
          <Typography
            sx={{ fontWeight: "bold" }}
            color={theme.palette.primary[400]}
          >
            {row.total}
          </Typography>
        );
      },
    });
  }

  return (
    <Box paddingBottom={3}>
      <TableContainer component={Box}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => {
                return (
                  <TableCell
                    style={index !== columns.length - 1 ? { width: "1px" } : {}}
                  >
                    <Typography fontWeight="bold">
                      {column?.headerName}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow
                key={row.bib}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map((column) => {
                  const content = column?.renderCell
                    ? column.renderCell(row)
                    : row?.[column?.field];

                  return <TableCell>{content}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const Marks = () => {
  const theme = useTheme();
  const { isNonMobile } = useOutletContext();
  const { comp_year_id, participant_id } = useParams();
  const { data: compData } = useGetCompsQuery(comp_year_id);

  const comp = compData ? compData[0] : {};

  // Judges
  const { data: judgesData } = useGetResultJudgesQuery({
    comp_year_id,
  });

  // Participant
  const { data: participantData } = useGetResultParticipantQuery({
    comp_year_id,
    participant_id,
  });
  const participant = useMemo(
    () => (participantData ? participantData[0] : {}),
    [participantData]
  );

  // Events
  const event_ids = participant?.event_ids ?? [];
  const { data: eventsData } = useGetResultEventsQuery({
    comp_year_id,
    event_ids,
  });

  // Rounds
  const round_ids = eventsData
    ? Object.values(eventsData).flatMap((x) => x?.rounds ?? [])
    : [];
  const { data: roundsData } = useGetResultRoundsQuery({
    comp_year_id,
    // event_ids,
    round_ids,
  });

  // Dances
  const dance_ids = roundsData
    ? [...new Set(Object.values(roundsData).flatMap((x) => x?.dances ?? []))]
    : [];
  const { data: dancesData } = useGetResultDancesQuery({
    comp_year_id,
    round_ids,
    dance_ids,
  });

  // Competitors
  const competitors = dancesData
    ? Object.values(dancesData).flatMap((x) => x?.competitors ?? [])
    : [];
  const competitor_ids = competitors
    ? [...new Set(competitors.flatMap((x) => x.id))]
    : [];
  const { data: competitorsData } = useGetResultCompetitorsQuery({
    comp_year_id,
    competitor_ids,
  });

  const context = {
    roundsData,
    dancesData,
    competitorsData,
    judgesData,
  };

  useEffect(() => {
    document.title = `Result - ${participant?.first_name} ${participant?.last_name}`;
  }, [participant]);

  if (isNonMobile === 10) {
    console.log("placeholder");
  }

  const danceEvents = eventsData ? Object.values(eventsData) : [];
  danceEvents.sort((a, b) => a.heat - b.heat);

  return (
    <Box m="1rem 1rem">
      <Header
        title={`${participant?.first_name} ${participant?.last_name}`}
        subtitle={comp?.comp_year_name}
        subtitleLinkTo={`/comps/${comp?.comp_year_id}/results`}
      />
      <Box mt="20px">
        {eventsData &&
          danceEvents.map((eventData) => (
            <Accordion
              defaultExpanded={danceEvents.length === 1}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  color={theme.palette.primary[400]}
                  fontWeight="bold"
                >
                  {eventData.heat}
                  {"\u2001"}
                </Typography>
                <Typography
                  variant="h5"
                  color={theme.palette.primary[600]}
                  fontWeight="bold"
                >
                  {eventData.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EventResult eventData={eventData} context={context} />
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
      <Box>{"\u2001"}</Box>
    </Box>
  );
};

export default Marks;
